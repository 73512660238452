import React from "react"
import { Link } from "gatsby"
import ReactTooltip from "react-tooltip"
import classes from "./layout.module.css"
import { BackTop } from "antd"

const Layout = props => {
  const { children } = props
  const [toggleNav, setToggleNav] = React.useState(false)

  const menuOnClick = e => {
    if (e.target.getAttribute("aria-current") !== null) {
      setToggleNav(!toggleNav)
    }
  }

  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <button
            className="nav-burger"
            href="#"
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </button>
          <nav id="swup" className="site-head-left">
            <ul className="nav">
              <li>
                <Link
                  activeClassName={classes.active}
                  to={`/`}
                  onClick={menuOnClick}
                >
                  ホーム
                </Link>
              </li>

              <li>
                <Link
                  activeClassName={classes.active}
                  to={`/illustration/`}
                  onClick={menuOnClick}
                >
                  イラストレーション
                </Link>
              </li>

              <li>
                <Link
                  activeClassName={classes.active}
                  to={`/design/`}
                  onClick={menuOnClick}
                >
                  デザイン
                </Link>
              </li>

              <li>
                <Link
                  activeClassName={classes.active}
                  to={`/profile/`}
                  onClick={menuOnClick}
                >
                  プロフィール
                </Link>
              </li>

              <li>
                <Link
                  activeClassName={classes.active}
                  to={`/works/`}
                  onClick={menuOnClick}
                >
                  制作実績
                </Link>
              </li>

              <li>
                <Link
                  activeClassName={classes.active}
                  to={`/blog/`}
                  onClick={menuOnClick}
                >
                  ブログ
                </Link>
              </li>

              <li>
                <Link
                  activeClassName={classes.active}
                  to={`/contact/`}
                  onClick={menuOnClick}
                >
                  お問い合わせ
                </Link>
              </li>
            </ul>
          </nav>

          <div className="site-head-right">
            <div className="social-links">
              <a
                href="https://www.instagram.com/MAKI_DESIGN.ILLUST/"
                title="Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <footer className="site-foot">
        &copy; 2020 <Link to={`/`}>MAKI SHONO</Link>
      </footer>

      <BackTop className={classes.backtotop}>
        <svg id="logo" width="28px" height="206px" viewBox="0 0 41 22">
          <path
            d="M16.245,204.505h-2V18.816H1.854l-0.753-1.658L14.492,1.837l1.753,0.658v14.321h10.9v2h-10.9V204.505z M4.057,16.816h10.188
	V5.159L4.057,16.816z"
          />
          <polyline points="-122.749,203.505 -122.749,2.495 -136.14,17.816 -111.849,17.816 " />
        </svg>
        <p>BACK TO TOP</p>
      </BackTop>
    </div>
  )
}
export default Layout
